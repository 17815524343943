<template>
    <div style="max-width: 400px;" class="input-group">
        <div class="input-group-prepend">
           <label class="input-group-text" for="inputPesquisa">Pesquisa:</label>
        </div>            
        <input id="inputPesquisa" type="text" class="form-control" aria-label="Digite o texto da pesquisa."
            v-model="query.search_input"
            @keyup.enter="getData($event)">
        <div class="input-group-append">
            <button type="button" class="btn btn-outline-secondary" @click="getData($event)"><i class="fa fa-search"></i></button>
            <button type="button" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="sr-only">Toggle Dropdown</span>
            </button>
            <div class="dropdown-menu">
                <a class="dropdown-item" href="#">Busca Avançada</a>
            </div>
        </div>        
    </div>
</template>
<script>
export default {
    name: 'MCtFiltroLista',
    props: ['columns', 'query'],
    data() {
      return {
        operators: {
          equal: 'IGUAL A (=)',
          not_equal: 'DIFERENTE DE (<>)',
          less_than: 'MENOR QUE (<)',
          greater_than: 'MAIOR QUE (>)',
          less_than_or_equal_to: 'MENOR OU IGUAL A (<=)',
          greater_than_or_equal_to: 'MAIOR OU IGUAL A (>=)',
          in: 'DENTRO (C*C)',
          like: 'CONTÉM (*C*)',
          between: 'ENTRE (C...C)'
        },
      }
    },
    methods: {
        getData(ev) {
            ev.preventDefault()
            this.$emit('getData')    
        }
    }
}
</script>

