<template>
  <div class="mct-form container">
    <mct-topo-lista :titulo="titulo" :subtitulo="`Listagem de ${titulo}`" v-show="!showModal">
      <div v-show="!showModal" class="btn-group" role="group" aria-label="Ações">
          <button type="button" class="btn btn-success text-white" aria-label="Left Align" @click="incluirNovo($event)">
            <i class="fa fa-plus fa-lg text-white" aria-hidden="true"></i> Novo Registro </button> 
          <div class="btn-group" role="group">
            <button id="btnMaisAcoes" type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-ellipsis-h fa-lg" aria-hidden="true"></i> Mais Ações </button>
            <div class="dropdown-menu" aria-labelledby="btnMaisAcoes">
              <a class="dropdown-item" href="#"><i class="fa fa-file-pdf-o text-danger mr-2"></i>Exportar em PDF</a>
              <a class="dropdown-item" href="#"><i class="fa fa-file-excel-o text-success mr-2"></i>Exportar em CSV</a>
            </div>
          </div>
      </div>
    </mct-topo-lista>

    <div class="container-fluid">
      <div class="d-flex flex-row justify-content-between align-items-center mb-2">
        <div></div>
        <mct-filtro-lista :columns="columns" :query="query"  @getData="getData"></mct-filtro-lista>
      </div>
      <slot>
        <mct-grid-lista :id="id" :columns="columns" :data="data" :query="query" :source="source" @toggleOrder="toggleOrder" :showModal="showModal" :showButtonCancelar="showButtonCancelar" @setDescricao="setDescricao">
        </mct-grid-lista>        
      </slot>
      <mct-rodape-lista :data="data" :query="query" @navigate="navigate" @getData="getData" :style="(typeof data.data !== 'undefined') && data.data.length > 0 ? '' : 'display: none!important;'"></mct-rodape-lista>          
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'

  import MctTopoLista from './MctTopoLista.vue'
  import MctRodapeLista from './MctRodapeLista.vue'
  import MctGridLista from './MctGridLista.vue'
  import MctFiltroLista from './MctFiltroLista.vue'
  import MctMixinsList from '../funcoes/MctMixinsList'

  export default {
    name: 'MctDataView',
    mixins: [MctMixinsList],
    components: {
        'MctTopoLista': MctTopoLista,
        'MctRodapeLista': MctRodapeLista,
        'MctGridLista' : MctGridLista,
        'MctFiltroLista': MctFiltroLista,
    },        
    props: ['source', 'titulo', 'showModal', 'showButtonCancelar'],
    data() {
      return {
        //id: '',
      }
    },
    watch: {
        source: function () {
          this.query.column = ''
          this.query.search_column = ''
          this.query.page = 1
          this.query.direction = 'asc'
          this.query.per_page = 10
          this.query.search_operator = 'equal'
          this.query.search_input = ''
          this.query.search_input2 = ''
          this.getData()      
        }
    },  
    methods: {
      onBeforeOpen() {
        this.cc = true;
      },
      navigate (page) {
            this.query.page = page
            this.getData()
      },
      toggleOrder(column) {
        if(column === this.query.column) {
          if(this.query.direction === 'desc') {
            this.query.direction = 'asc'
          } else {
            this.query.direction = 'desc'
          }
        } else {
          this.query.column = column
          this.query.direction = 'asc'
        }
        this.getData()
      },      
      setDescricao (data ){        
          this.$emit('setDescricao', data)
      },
    },
  }
</script>